<template>
  <Menu/>
  <router-view/>
  <MensajeCookies />
</template>
<script>
import { defineAsyncComponent } from "vue";
export default {
    name: 'App',
    components: {
    Menu: defineAsyncComponent(() =>
      import(/* webpackChunkName: "menu" */ "@/components/menu/Menu.vue")
    ),
    MensajeCookies: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "MensajeCookies" */ "@/components/mensajeCookies/MensajeCookies.vue"
      )
    ),
  },
}
</script>
