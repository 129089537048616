import { createStore } from 'vuex'

export default createStore({
  namespaced: true,//para que salgan bien los datos
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
