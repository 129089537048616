export default {
    //Conexion bbdd test
    test:{
        "graphqlEndpoint": "https://tifdxxnr6zgnrnerd7yd3tz52e.appsync-api.eu-west-1.amazonaws.com/graphql",
        "region": "eu-west-1",
        "authType": "API_KEY",
        "apiKey": "da2-5i6s5knebfd4fmlyzp3lavvgpy"
    },
    //Conexion bbdd produccion (Apunta a test de momento porq no hay BBDD de producción)
    produccion:{
        "graphqlEndpoint": "https://bia66f5yunci3i52wtmkgombre.appsync-api.eu-west-1.amazonaws.com/graphql",
        "region": "eu-west-1",
        "authType": "API_KEY",
        "apiKey": "da2-6qa6oah6x5acvogv6txmndojsy"
    }
  }
  
    
  