import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: {
      name: "LaFelguera"
    }
  },
  {
    path: '/adiro',
    name: 'Adiro',
    component: () => import(/* webpackChunkName: "adiro_view" */ '../views/AdiroView.vue'),
    meta: {
      title: `Home - Adiro(R)`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina principal de Adiro'
        },
        {
          property: 'og:description',
          content: 'Pagina principal de Adiro.'
        }
      ]
    }
  },
  {
    path: '/la-felguera',
    name: 'LaFelguera',
    component: () => import(/* webpackChunkName: "la_felguera_view" */ '../views/LaFelgueraView.vue'),
    meta: {
      title: `Home - La Felguera`,
      metaTags: [
        {
          name: 'description',
          content: 'La Felguera, que celebra este año su 80 aniversario, es un centro referente global por la producción a nivel mundial del 100% el ácido acetilsalicílico utilizado por Bayer en sus productos.'
        },
        {
          property: 'og:description',
          content: 'La Felguera, que celebra este año su 80 aniversario, es un centro referente global por la producción a nivel mundial del 100% el ácido acetilsalicílico utilizado por Bayer en sus productos.'
        },
        {
          name: 'keywords',
          content: 'Bayer La felguera, La felguera, felguera, bayer felguera'
        },
        {
          name: 'author',
          content: 'Bayer La felguera'
        }
      ]
    }
  },
  {
    path: '/epil',
    name: 'ePil',
    component: () => import(/* webpackChunkName: "epil_view" */ '../views/EpilView.vue'),
    meta: {
      title: `Home - ePil`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina principal de ePil'
        },
        {
          property: 'og:description',
          content: 'Pagina principal de ePil.'
        }
      ]
    }
  },
  {
    path: '/politica-de-privacidad',
    name: 'Politica De Privacidad',
    component: () => import(/* webpackChunkName: "politica_de_privacidad_view" */ '../views/PoliticaDePrivacidad.vue'),
    meta: {
      title: `Politica de Privacidad`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina principal de Politica de Privacidad. Aquí encontrarás cómo gestionamos el tratamiento de datos personales.'
        },
        {
          property: 'og:description',
          content: 'Pagina principal de Politica de Privacidad. Aquí encontrarás cómo gestionamos el tratamiento de datos personales.'
        }
      ]
    }
  },
  {
    path: '/ficha-tecnica',
    name: 'Ficha Tecnica',
    component: () => import(/* webpackChunkName: "ficha_tecnica_view" */ '../views/FichaTecnica.vue'),
    meta: {
      title: `Ficha Tecnica`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina principal de la ficha técnica. Aquí encontrarás el contenido mínimo de la Ficha Técnica de producto'
        },
        {
          property: 'og:description',
          content: 'Pagina principal de la ficha técnica. Aquí encontrarás el contenido mínimo de la Ficha Técnica de producto'
        }
      ]
    }
  },
  {
    path: '/referencias',
    name: 'Referencias',
    component: () => import(/* webpackChunkName: "referencias_view" */ '../views/Referencias.vue'),
    meta: {
      title: `Referencias`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina principal sobre las referencias. Aquí encontrarás el contenido mínimo de las referencias del producto'
        },
        {
          property: 'og:description',
          content: 'Pagina principal sobre las referencias. Aquí encontrarás el contenido mínimo de las referencias del producto'
        }
      ]
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "error_404_view" */ '../views/View_404.vue'),
    meta: {
      title: `404`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina error 404.'
        },
        {
          property: 'og:description',
          content: 'Pagina error 404.'
        }
      ]
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: {
      name: "404"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  // Borramos todas las etiquetas meta que son de la antigua pagina
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  //Comprobamos que tiene puesta las metaTags
  if (!to.meta.metaTags) return next();
  
  to.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // Usamos esto para rastrear qué metaetiquetas creamos para no interferir con otras.
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
})

export default router
