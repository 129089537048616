import '@/styles/css/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
//Apollo y conexión con BBDD
import { createApp,provide, h } from 'vue'
import { ApolloClient, createHttpLink,InMemoryCache, ApolloLink } from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApolloProvider } from '@vue/apollo-option'
import AppSyncConfig from "./appSync-config";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
//Fin de la conexión con BBDD

const url = AppSyncConfig.test.graphqlEndpoint;
const region = AppSyncConfig.test.region;
const auth= {
        type: AppSyncConfig.test.authType,
        apiKey: AppSyncConfig.test.apiKey,
};
const httpLink = createHttpLink({uri: url});
const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
    link,
    cache,
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

const app = createApp({
    setup () {
        provide(DefaultApolloClient, apolloClient)
      },
    render: () => h(App),
});
app.use(apolloProvider,store).use(router).mount('#app')